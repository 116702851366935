import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import AddPreset from "./AddPreset";
import ListPresets from "./ListPresets";

export default function GenerationPresets() {
  return (
    <FlexBox column gap padding>
        <AddPreset />
        <ListPresets />
    </FlexBox>
  );
}
