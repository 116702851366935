import { enableCategory } from "Functions/Category/enableCategory";
import { updateSlot } from "Functions/Slot/updateSlot";
import { slots } from "Signals/slots";

export const loadHandCategory = (category, hand) => {
  // TODO: This doesn't account for locked slots
  hand.slots.forEach((slot) => {
    if (slot.categoryId === category.id) {
      const slotToUpdate = slots.value.find((s) => s.id === slot.id);
      updateSlot({
        ...slotToUpdate,
        cardIds: [...slot.cardIds],
        wildcard: false,
        enabled: true,
      });
      // Also enable category
      enableCategory(slot.categoryId);
    }
  });
};
