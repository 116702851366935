import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import AddSnapshot from "./AddSnapshot";
import DeleteSnapshots from "./DeleteSnapshots";
import { Card } from "@blueprintjs/core";
import GridWidth from "./GridWidth";
import Filter from "./Filter/Filter";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function Actions() {
  return (
    <HeadingCard title="View Options" compact>
      <FlexBox spaceBetween gap wrap>
        <Filter />
        <div>
          <AddSnapshot />
          <DeleteSnapshots />
        </div>
        <GridWidth />
      </FlexBox>
    </HeadingCard>
  );
}
