import { Button, Card, Checkbox, H5, Tooltip } from "@blueprintjs/core";
import { exportData } from "Functions/Data/exportData";
import {
  exportGenerationPresets,
  exportLayouts,
  exportSettings,
  exportSnapshots,
} from "Signals/appState";
import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { generationPresets } from "Signals/generationPresets";
import { layouts } from "Signals/layouts";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import PTEXT from "UtilityComponents/PTEXT";
import { getSizeInMB } from "UtilityFunctions/getSizeInMB";
import React from "react";

export default function ExportData() {
  const [cardsSize, setCardsSize] = React.useState(0);
  const [snapshotsSize, setSnapshotsSize] = React.useState(0);

  React.useEffect(() => {
    getCardsSize();
    getSnapshotsSize();
  }, []);

  const getCardsSize = () => {
    setCardsSize(getSizeInMB(cards.value));
  };
  const getSnapshotsSize = () => {
    setSnapshotsSize(getSizeInMB(snapshots.value));
  };

  return (
    <HeadingCard title="Export Data">
      <FlexBox column gap>
        <PTEXT>
          Cards: {cards.value.length} ({cardsSize}mb)
        </PTEXT>
        <PTEXT>Tokens: {tokens.value.length}</PTEXT>
        <PTEXT>Tags: {tags.value.length}</PTEXT>
        <PTEXT>Slots: {slots.value.length}</PTEXT>
        <PTEXT>Categories: {categories.value.length}</PTEXT>
        <FlexBox flexStart>
          <Checkbox
            checked={exportSnapshots.value}
            onChange={(e) => (exportSnapshots.value = e.target.checked)}
          />
          <PTEXT>
            Snapshots: {snapshots.value.length} ({snapshotsSize}mb)
          </PTEXT>
        </FlexBox>
        <FlexBox flexStart>
          <Checkbox
            checked={exportLayouts.value}
            onChange={(e) => (exportLayouts.value = e.target.checked)}
          />
          <PTEXT>Saved Layouts: {layouts.value.length}</PTEXT>
        </FlexBox>
        <FlexBox flexStart>
          <Checkbox
            checked={exportGenerationPresets.value}
            onChange={(e) => (exportGenerationPresets.value = e.target.checked)}
          />
          <PTEXT>Generation Presets {generationPresets.value.length}</PTEXT>
        </FlexBox>
        <FlexBox flexStart>
          <Checkbox
            checked={exportSettings.value}
            onChange={(e) => (exportSettings.value = e.target.checked)}
          />
          <PTEXT>UI and API Settings</PTEXT>
        </FlexBox>
        <FlexBox>
          <Button onClick={exportData} intent="primary" icon="export">
            Export Data
          </Button>
        </FlexBox>
      </FlexBox>
    </HeadingCard>
  );
}
