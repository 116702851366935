import { Button, Card, H5, Tooltip } from "@blueprintjs/core";
import React from "react";
import Filter from "./Filter/Filter";
import SlotOptions from "./SlotOptions/SlotOptions";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import Sort from "./Filter/Sort";
import { addTile } from "Functions/Mosaic/addTile";
import { currentSlot } from "Signals/slots";
import ListSelectedCards from "./ListSelectedCards/ListSelectedCards";
import EmptyTile from "UtilityComponents/EmptyTile";

export default function ListCardsOptions() {
  const slot = currentSlot.value;
  if (!slot) return <EmptyTile>No slot selected</EmptyTile>;

  return (
    <FlexBox gap column padding wrap>
      <Filter />
      <SlotOptions slot={slot} />
      <ListSelectedCards slot={slot} />
    </FlexBox>
  );
}
