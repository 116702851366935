import React, { useState } from "react";
import { Mosaic, MosaicWindow } from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import ELEMENTS from "../Data/Mosaic/Elements";
import { currentLayout } from "Signals/appState";

export default function MyMosaic() {
  const handleChange = (e) => {
    currentLayout.value = e;
  };

  const style = {
    height: "100%",
    width: "100%",
    margin: "0",
  };

  return (
    <div style={style}>
      <Mosaic
        resize={{ minimumPaneSizePercentage: 5 }}
        blueprintNamespace="bp5"
        value={currentLayout.value}
        onChange={handleChange}
        renderTile={(id, path) => (
          <MosaicWindow
            path={path}
            title={ELEMENTS.find((element) => element.id === id).title}
          >
            {ELEMENTS.find((element) => element.id === id).element}
          </MosaicWindow>
        )}
      />
    </div>
  );
}
