import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import ListCategoriesItem from "./ListCategoriesItem";
import { categories } from "Signals/categories";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListCategories({ hand }) {
  return (
    <HeadingCard title="Load cards by category">
      <FlexBox gap wrap>
        {categories.value.slice().sort(
          (a, b) => a.displayOrder - b.displayOrder
        ).map((category) => (
          <ListCategoriesItem
            key={category.id}
            category={category}
            hand={hand}
          />
        ))}
      </FlexBox>
    </HeadingCard>
  );
}
