import { Button, Callout } from "@blueprintjs/core";
import { loadDefaultDeck } from "Functions/Data/loadDefaultDeck";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function LoadDefaultDeckPrompt() {
  return (
    <Callout title="Load default deck">
      <p>
        It looks like you don't have any data yet. Load the default starter
        deck?
      </p>
      <Button onClick={loadDefaultDeck} intent="primary">
        Load default deck
      </Button>
    </Callout>
  );
}
