import EmptyTile from "UtilityComponents/EmptyTile";
import React, { useState } from "react";
import Actions from "./Actions/Actions";
import FlexBox from "UtilityComponents/FlexBox";
import { settings } from "Signals/settings";
import { getFilteredSnapshots } from "Functions/Snapshot/getFilteredSnapshots";
import ListSnapshotsItem from "./ListSnapshotsItem";

export default function ListSnapshots() {
  const snapshots = getFilteredSnapshots().sort((a, b) => {
    return b.created - a.created;
  });

  const getStyle = () => {
    return {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, minmax(${
        settings.value.listSnapshotsGridWidth || 300
      }px, 1fr))`,
      // gridTemplateColumns: `repeat(auto-fill, ${listSnapshotsGridWidth || 300}px)`
    };
  };

  return (
    <FlexBox column>
      <FlexBox column padding gap>
        <Actions />
        {!snapshots || snapshots.length === 0 ? (
          <FlexBox fullHeight>
            <EmptyTile>No snapshots</EmptyTile>
          </FlexBox>
        ) : null}
      </FlexBox>

      {snapshots && snapshots.length > 0 && (
        <div style={getStyle()}>
          {snapshots
            .slice()
            .sort((a, b) => {
              return b.created - a.created;
            })
            .map((snapshot, index) => (
              <ListSnapshotsItem snapshot={snapshot} key={snapshot.id} />
            ))}
        </div>
      )}
    </FlexBox>
  );
}
