import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import Settings from "./Settings/Settings";
import ToggleEnabled from "./Settings/ToggleEnabled";
import Image from "./Image/Image";
import ToggleImg2Img from "./Settings/ToggleImg2Img";
import MaskImage from "./MaskImage/MaskImage";
import I2ISettings from "./I2ISettings/I2ISettings";
import { apiConnected } from "Signals/appState";
import NoApiConnection from "UtilityComponents/NoApiConnection";

export default function ControlNet() {
  if(!apiConnected.value) return (<NoApiConnection />);
  return (
    <FlexBox column padding gap>
      <Image />
      <ToggleImg2Img />
      <I2ISettings />
      <MaskImage />
      <ToggleEnabled />
      <Settings />
    </FlexBox>
  );
}
