import { Card, HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { checkpoints } from "Signals/appState";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function SecondaryCheckpoint() {
  const handleChange = (event) => {
    apiSettings.value = {
      ...apiSettings.value,
      secondaryCheckpoint: event.target.value,
    };
  };

  return (
    <HeadingCard title="Secondary Checkpoint">
      <HTMLSelect
        options={checkpoints.value.length > 0 ? checkpoints.value : ["No checkpoints detected"]}
        onChange={handleChange}
        value={apiSettings.value.secondaryCheckpoint}
      />
    </HeadingCard>
  );
}
