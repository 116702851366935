import { computed, effect, signal } from "@preact/signals-react";
import { defaultLayouts } from "Data/Layouts/defaultLayoutsData";
import { refreshData } from "Functions/API/refreshData";

export const prompt = signal({
  positive: "",
  negative: "",
});

// Layout
export const currentLayout = signal(defaultLayouts[0].layout);

// API
export const apiStatus = signal("disconnected");
export const apiConnected = signal(false);
export const apiProgress = signal(0);

export const cnAvailable = signal(false);

export const cnImage = signal(null);
export const maskImage = signal(null);

// API available options
export const checkpoints = signal([]);
export const samplers = signal([]);
export const upscalers = signal([]);
export const cnModels = signal([]);
export const cnModules = signal([]);

// Data
export const exportSnapshots = signal(true);
export const exportLayouts = signal(true);
export const exportSettings = signal(true);
export const exportGenerationPresets = signal(true);

// View snapshots filter
export const snapshotFilter = signal({
  tagIds: [],
  inclusiveTags: false,
  saved: false,
});

// Effects
effect(() => {
  // Refresh API data when the API first connects
  if (apiConnected.value === true) {
    refreshData();
  }
});
