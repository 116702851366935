import { Button, Checkbox, Popover } from "@blueprintjs/core";
import { addSlot } from "Functions/Slot/addSlot";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function AddSlot({ categoryId }) {
  // NOTE: Also used by ListCategories
  const [name, setName] = React.useState("");
  const [addTag, setAddTag] = React.useState(true);

  const handleAdd = () => {
    addSlot({
      name: name,
      categoryId: categoryId,
      addTag: addTag,
    });
    setName("");
  };
  return (
    <FlexBox>
      <Popover
        autoFocus
        content={
          <FlexBox column gap padding>
            <PTEXT>Slot name:</PTEXT>
            <input
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAdd();
                }
              }}
            />
            <Checkbox
              checked={addTag}
              onChange={(e) => setAddTag(e.target.checked)}
            >Create tag with same name</Checkbox>
            <Button small intent="primary" onClick={handleAdd}>Add</Button>
          </FlexBox>
        }
      >
        <Button intent="primary">
          Add Slot
        </Button>
      </Popover>
    </FlexBox>
  );
}
