import { snapshotFilter } from "Signals/appState";
import { snapshots } from "Signals/snapshots";

export const getFilteredSnapshots = () => {
  const filter = snapshotFilter.value;

  if (filter.tagIds.length === 0 && !filter.saved) return snapshots.value;

  return snapshots.value.filter((snapshot) => {
    return (
      ((!filter.saved || (filter.saved && snapshot.save)) &&
        filter.tagIds.length === 0) ||
      (filter.inclusiveTags &&
        filter.tagIds.every((tagId) => snapshot.tagIds.includes(tagId))) ||
      (!filter.inclusiveTags &&
        filter.tagIds.some((tagId) => snapshot.tagIds.includes(tagId)))
    );
  });
};
