import { InputGroup } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function AdetailerModel() {
  const handleChange = (event) => {
    apiSettings.value = {
      ...apiSettings.value,
      adetailerModel: event.target.value,
    };
  };
  return (
    <FlexBox column gap>
      <PTEXT>Model Name</PTEXT>
      <InputGroup
        value={apiSettings.value.adetailerModel}
        onChange={handleChange}
        placeholder="Adetailer Model"
      />
    </FlexBox>
  );
}
