import { Checkbox, Tooltip } from "@blueprintjs/core";
import { snapshotFilter } from "Signals/appState";
import React from "react";

export default function ToggleInclusiveTags() {
  return (
    <Tooltip 
    hoverOpenDelay={500}
    content="Inclusive search will only show snapshots that have all of the selected tags. Exclusive search will show snapshots that have any of the selected tags.">
      <Checkbox
        className="m-0"
        checked={snapshotFilter.value.inclusiveTags}
        onChange={() =>
          (snapshotFilter.value = {
            ...snapshotFilter.value,
            inclusiveTags: !snapshotFilter.value.inclusiveTags,
          })
        }
        label="Inclusive search"
      />
    </Tooltip>
  );
}
