import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import UpdatePrompt from "./UpdatePrompt";
import FlexBox from "UtilityComponents/FlexBox";

export default function Actions() {
  return (
    <HeadingCard title="Prompt Actions">
        <UpdatePrompt />
    </HeadingCard>
  );
}
