import { addTile } from "Functions/Mosaic/addTile";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import HeadingText from "UtilityComponents/HeadingText";
import React from "react";

export default function GettingStarted() {
  return (
    <FlexBox column gap padding>
      <HeadingCard title="Getting Started">
        <div className="bp5-running-text">
          <p>
            deckai.io is a tool to help build prompts for Stable Diffusion
            image generators, especially the{" "}
            <a href="https://github.com/AUTOMATIC1111/stable-diffusion-webui">
              AUTOMATIC1111 web UI
            </a>
            .
          </p>
          <HeadingText>Building a prompt</HeadingText>
          <p>
            A prompt is generated based on the current state of the prompt
            board.
          </p>
          <p>
            The <a onClick={() => addTile("ListCategories")}>Prompt Board</a>{" "}
            contains one or more Categories.
          </p>
          <p>
            <a onClick={() => addTile("SingleCategory")}>Categories</a> contain
            one or more Slots.
          </p>
          <p>
            <a onClick={() => addTile("SingleSlot")}>Slots</a> contain one or
            more Cards.
          </p>
          <p>
            <a onClick={() => addTile("ListCards")}>Cards</a> contain one or
            more Tokens.
          </p>
          <p>
            <a onClick={() => addTile("SingleCard")}>Tokens</a> are processed to
            generate the final <a onClick={() => addTile("Prompt")}>Prompt</a>.
          </p>
          <HeadingText>Creating Snapshots</HeadingText>
          <p>
            A <a onClick={() => addTile("SingleSnapshot")}>Snapshot</a> captures
            a particular state of the prompt board along with a generated image.
            Your <a onClick={() => addTile("SavedSnapshots")}>Saved Snapshots</a> are how you can save and view your favourite prompts.
          </p>
          <p>
            In order to generate images, an active connection to an AUTOMATIC1111 API is required. See <a onClick={() => addTile("APISettings")}>API Settings</a> for more information.
          </p>
        </div>
      </HeadingCard>
    </FlexBox>
  );
}
