import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React, { useEffect } from "react";

export default function GuidanceEnd() {
  const [value, setValue] = React.useState(apiSettings.value.cnGuidanceEnd);
  useEffect(() => {
    setValue(apiSettings.value.cnGuidanceEnd);
  }, [apiSettings.value.cnGuidanceEnd]);
  return (
    <FlexBox column>
      <p>Guidance End</p>
      <Slider
        min={0}
        max={1}
        stepSize={0.1}
        labelValues={[]}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            cnGuidanceEnd: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      />
    </FlexBox>
  );
}
