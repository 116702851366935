import { Button, Popover } from "@blueprintjs/core";
import addCategory from "Functions/Category/addCategory";
import React, { useState } from "react";

export default function AddCategory() {
  const [name, setName] = useState("");

  const handleClick = () => {
    addCategory(name);
    setName("");
  };

  return (
    <Popover
      autoFocus
      content={
        <div className="p-1">
          <p>Category name:</p>
          <input
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleClick();
              }
            }}
          />
        </div>
      }
    >
      <Button intent="primary" icon="add">
        Add Category
      </Button>
    </Popover>
  );
}
