import { apiSettings } from "Signals/apiSettings";
import { cnAvailable, cnModels } from "Signals/appState";
import axios from "axios";

export const getCnModels = async () => {
  const url = `${apiSettings.value.apiUrl}/controlnet/model_list`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.model_list.length === 0) {
      return;
    }
    cnModels.value = data.model_list;
    cnAvailable.value = true;
  } catch (error) {
    cnModels.value = [];
    cnAvailable.value = false;
  }
};
