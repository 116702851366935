export const getDefaultApiSettings = () => {
  return {
    apiUrl: "http://127.0.0.1:7860",
    pollApi: false,

    checkpoint: null,
    secondaryCheckpoint: null,

    cfg_scale: 7,
    clip_skip: 2,
    height: 768,
    width: 512,
    steps: 20,

    sampler: null,

    enable_regional_prompter: false,

    restore_faces: false,

    adetailerEnabled: false,
    ad_restore_face: false,
    ad_use_checkpoint: false,
    adetailerModel: "face_yolov8n.pt",

    HRCheckpoint: null,
    denoising_strength: 0,
    hr_scale: 1.4,
    HRSteps: 10,
    enable_hr: false,

    upscaler: null,

    refiner_enabled: false,
    refiner_checkpoint: null,
    refiner_switch_at: 0.4,

    cnEnabled: false,
    cnWeight: 0.5,
    cnResizeMode: 1,
    cnProcessorRes: 512,
    cnGuidanceStart: 0,
    cnGuidanceEnd: 1,
    cnControlMode: 0,
    cnPixelPerfect: false,
    cnThresholdA: 0,
    cnThresholdB: 0,

    img2imgEnabled: false,
    i2iDenoisingStrength: 0.5,
    useMask: false,
    useCnMask: false ,

    // Mask settings
    initial_noise_multiplier: 1,
    inpaint_full_res: 0,
    inpaint_full_res_padding: 32,
    inpainting_fill: 1,
    inpainting_mask_invert: 0,
    mask_blur: 4,
    mask_blur_x: 4,
    mask_blur_y: 4,



  };
};
