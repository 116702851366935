import React, { useEffect, useState } from "react";
import ListCardsItem from "./ListCardsItem";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import EmptyTile from "UtilityComponents/EmptyTile";
import ListCardsOptions from "Tiles/ListCardsOptions/ListCardsOptions";
import AddCard from "Tiles/ListCardsOptions/AddCard";
import { currentSlot } from "Signals/slots";
import { filteredCards } from "Signals/cards";
import { settings } from "Signals/settings";

export default function ListCards() {
  const columnWidth = settings.value.cardWidth + 6;
  const columnHeight = settings.value.cardHeight + 6;
  if (!currentSlot.value) return <EmptyTile>No slot selected</EmptyTile>;

  const cards = filteredCards.value;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
        gap: "0.5rem",
      }}
    >
      <div style={{ flexGrow: 1, minHeight: 0 }}>
        <AutoSizer>
          {({ height, width }) => {
            const columnCount = Math.floor(width / columnWidth);
            const Cell = ({ columnIndex, rowIndex, style }) => {
              if (rowIndex === 0 && columnIndex === 0) return <AddCard />;
              const card = cards[rowIndex * columnCount + columnIndex - 1];

              const newStyle = {
                ...style,
                top: style.top + 1,
                left: style.left + 1,
              };

              return card ? (
                <div style={newStyle} key={card.id}>
                  <ListCardsItem
                    card={card}
                    selected={currentSlot.value.cardIds.includes(card.id)}
                  />
                </div>
              ) : null;
            };
            return (
              <Grid
                columnCount={columnCount}
                columnWidth={() => columnWidth}
                height={height}
                rowCount={Math.max(1, Math.ceil(cards.length / columnCount + 1))}
                rowHeight={() => columnHeight}
                width={width}
              >
                {Cell}
              </Grid>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
}
