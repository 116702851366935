import React from "react";
import { Tag } from "@blueprintjs/core";
import { deleteToken } from "Functions/Token/deleteToken";
import { currentTokenId } from "Signals/tokens";
import { updateToken } from "Functions/Token/updateToken";

export default function ListTokensItem({ token }) {
  const handleClick = () => {
    currentTokenId.value = token.id;
  };

  const handleDoubleClick = () => {
    updateToken({
      ...token,
      enabled: !token.enabled,
    });
  };

  const handleRemove = () => {
    deleteToken(token.id);
  };

  return (
    <Tag
      large
      onRemove={handleRemove}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      interactive
      active={token.id === currentTokenId.value}
      minimal={!token.enabled}
      intent={token.negative ? "danger" : token.lora ? "success" : "none"}
    >
      {token.value} {token.power !== 1 && <span>({token.power})</span>}
    </Tag>
  );
}
