import React from "react";
import ListCategoriesItem from "./ListCategoriesItem/ListCategoriesItem";
import AddCategory from "./Actions/AddCategory";
import { categories } from "Signals/categories";
import FlexBox from "UtilityComponents/FlexBox";
import ReorderCategoriesButton from "./Actions/ReorderCategoriesButton";
import HeadingCard from "UtilityComponents/HeadingCard";
import Actions from "./Actions/Actions";
import LoadDefaultDeckPrompt from "./LoadDefaultDeckPrompt";

export default function ListCategories() {
  return (
    <FlexBox padding column gap>
      {categories.value.length === 0 && <LoadDefaultDeckPrompt />}
      {categories.value.length > 0 && (
        <FlexBox column alignStart gap>
          {categories.value
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((category) => (
              <ListCategoriesItem key={category.id} category={category} />
            ))}
        </FlexBox>
      )}
      <Actions />
    </FlexBox>
  );
}
