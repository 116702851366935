import React from 'react'

export default function PTEXT(props) {
    const style = {
        margin: 0,
        textTransform: props.uppercase ? "uppercase" : "none",
        textAlign: props.alignCenter ? "center" : "left",
    }
  return (
    <p 
// @ts-ignore
    style={style}>{props.children}</p>
  )
}
