import { Card, NonIdealState } from "@blueprintjs/core";
import DisplayCard from "UtilityComponents/DisplayCard";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import ListCardsItem from "../../ListCards/ListCardsItem";
import ListSelectedCardsItem from "./ListSelectedCardsItem";
import HeadingCard from "UtilityComponents/HeadingCard";
import EmptyCard from "UtilityComponents/EmptyCard";

export default function ListSelectedCards({ slot }) {

  return (
    <HeadingCard compact title="Selected Cards">

      <FlexBox>
      {slot.cardIds.length === 0 && <EmptyCard><NonIdealState>No cards selected</NonIdealState></EmptyCard>}
        {slot.cardIds.length > 0 &&
          slot.cardIds.map((id) => (
            <ListSelectedCardsItem key={id} cardId={id} />
          ))}
      </FlexBox>
    </HeadingCard>
  );
}
