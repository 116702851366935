import { effect, signal } from "@preact/signals-react";

import { saveGenerationPresetsToDb } from "Data/GenerationPresets/saveGenerationPresetsToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const generationPresets = signal([]);

// Save to DB on change
effect(() => {
  if (!generationPresets.value || generationPresets.value.length === 0) return;
  saveGenerationPresetsToDb(generationPresets.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("generationPresets");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!generationPresets.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "generationPresets",
    data: generationPresets.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (
    event.data.type === "generationPresets" &&
    event.data.id !== broadcastID
  ) {
    isUpdatingFromBroadcast = true;
    generationPresets.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
