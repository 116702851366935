import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./App.css";
import React, { useEffect } from "react";
import MyMosaic from "Mosaic/Mosaic";
import FlexBox from "UtilityComponents/FlexBox";
import APIStatus from "Menu/APIStatus/APIStatus";
import DefaultLayoutsMenu from "Menu/DefaultLayouts/DefaultLayoutsMenu";
import SavedLayouts from "Menu/SavedLayouts/SavedLayouts";
import { getInitialData } from "Functions/Data/getInitialData";
import NavMenu from "Menu/NavMenu";
import PTEXT from "UtilityComponents/PTEXT";
import { Button } from "@blueprintjs/core";
const effects = require("Signals/effects");

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [mobileConfirmed, setMobileConfirmed] = React.useState(false);
  useEffect(() => {
    getInitialData().finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <div>Loading...</div>;

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const style = {
    display: "flex",
    // flexDirection: "column",
    height: "100vh",
    // margin: "0",
    // padding: "0",
    // width: "100vw",
  };

  if(isMobileDevice() && !mobileConfirmed) {
    return (
      <div
        // @ts-ignore
        style={style}
      >
        <FlexBox column padding fullWidth>
          <p className="alignCenter">deckai.io doesn't work on narrow screens</p>
          <p className="alignCenter">Try again on a PC or laptop :)</p>
          <Button onClick={() => setMobileConfirmed(true)} intent="danger">Let me try anyway</Button>
        </FlexBox>
      </div>
    );
  }

  return (
    <div
      // @ts-ignore
      style={style}
    >

      <FlexBox fullWidth alignStart>
        <NavMenu />
        {/* <APIStatus /> */}
        <MyMosaic />
      </FlexBox>
    </div>
  );
}

export default App;
