import { Button, Card } from "@blueprintjs/core";
import { deleteLayout } from "Functions/Layout/deleteLayout";
import { loadLayout } from "Functions/Layout/loadLayout";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function ListLayoutsItem({ layout }) {
  const handleClick = () => {
    loadLayout(layout);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    deleteLayout(layout.id);
  };
  const style = {
    width: "100%",
  };
  return (
      <Card compact style={style} interactive onClick={handleClick}>
        <FlexBox spaceBetween>
          {layout.name}
          <Button
            small
            icon="trash"
            onClick={handleDelete}
            minimal
            intent="danger"
          />
        </FlexBox>
      </Card>
  );
}
