import React from "react";

export default function ShowImage({image}) {

  let src = "";
  if(image.startsWith("data:image")) {
    src = image;
  } else {
    src = `data:image/jpg;base64,${image}`;
  }

  const style = {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%",
    objectFit: "contain",
  };
  // @ts-ignore
  return <img style={style} src={src} />;
}
