import { Checkbox, Tooltip } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleCNMask() {
  const toggleMask = () => {
    apiSettings.value = {
      ...apiSettings.value,
      useCnMask: !apiSettings.value.useCnMask,
    };
  };
  return (
    <Tooltip content="Enable this if you are using a ControlNet inpaint model">
      <Checkbox checked={apiSettings.value.useCnMask} onChange={toggleMask}>
        ControlNet Mask
      </Checkbox>
    </Tooltip>
  );
}
