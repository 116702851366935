import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnModules } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function Module() {
  const handleChange = (value) => {
    apiSettings.value = {
      ...apiSettings.value,
      cnModule: value,
    };
  };
  return (
    <FlexBox column>
      <p>Module</p>
      <HTMLSelect
        options={cnModules.value.length > 0 ? cnModules.value : ["No modules detected"]}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        value={apiSettings.value.cnModule}
      />
    </FlexBox>
  );
}
