import ELEMENTS from "Data/Mosaic/Elements";
import { currentLayout } from "Signals/appState";

export const addTile = (tileId, width) => {
  let layout = currentLayout.value
  let newLayout;
  if (layout) {
    // Check whether tile is already in layout - if so we don't want to open it again
    if (JSON.stringify(layout).includes(`"${tileId}"`)) return;

    // Check if we have an element matching this tileId
    const element = ELEMENTS.find((element) => element.id === tileId);
    if(!element) return

    // Get width from ELEMENTS layout
    const width = element.width;

    newLayout = {
      direction: "row",
      first: tileId,
      second: layout,
      splitPercentage: width ? width : 20,
    };
  } else {
    newLayout = tileId;
  }
  currentLayout.value = newLayout;
};
