import React from "react";
import ClearSelection from "./ClearSelection";
import ToggleMultiSelect from "./ToggleMultiSelect";
import SelectAll from "./SelectAll";
import ToggleWildcard from "./ToggleWildcard";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import { addTile } from "Functions/Mosaic/addTile";

export default function SlotOptions({ slot }) {

  const openSettings = () => {
    addTile("SingleSlot");
  }

  return (
    <HeadingCard compact title={`Slot Options - ${slot.name}`}>
        <FlexBox wrap gap>
          <ToggleMultiSelect slot={slot} />
          <ToggleWildcard slot={slot} />
          <SelectAll slot={slot} />
          <ClearSelection slot={slot} />
        </FlexBox>
    </HeadingCard>
  );
}
