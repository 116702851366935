import { getDefaultApiSettings } from "Data/ApiSettings/defaultApiSettings";
import { defaultSettings } from "Data/Settings/defaultSettings";
import { deleteAllDataFromDb } from "Data/deleteAllDataFromDb";
import { apiSettings } from "Signals/apiSettings";
import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { generationPresets } from "Signals/generationPresets";
import { layouts } from "Signals/layouts";
import { settings } from "Signals/settings";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";

export const clearAllData = async () => {
  deleteAllDataFromDb();

  categories.value = [];
  slots.value = [];
  cards.value = [];
  tokens.value = [];
  tags.value = [];
  snapshots.value = [];
  layouts.value = [];
  generationPresets.value = [];
  apiSettings.value = getDefaultApiSettings();
  settings.value = defaultSettings;

};
