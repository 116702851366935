import { apiSettings } from "Signals/apiSettings";
import { generationPresets } from "Signals/generationPresets";
import generateGuid from "UtilityFunctions/generateGUID";

export const addGenerationPreset = (name) => {
  generationPresets.value = [
    ...generationPresets.value,
    {
      id: generateGuid(),
      name: name,
      data: apiSettings.value,
    },
  ];
}