import { Card, Slider } from "@blueprintjs/core";
import { settings } from "Signals/settings";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function UISettings() {
  return (
    <FlexBox padding column gap>
      <HeadingCard title="UI Settings">
        <p>Card Width</p>
        <Slider
          value={settings.value.cardWidth}
          onChange={(value) =>
            (settings.value = { ...settings.value, cardWidth: value })
          }
          min={10}
          max={1000}
          labelValues={[]}
        />
        <p>Card Height</p>
        <Slider
          value={settings.value.cardHeight}
          onChange={(value) =>
            (settings.value = { ...settings.value, cardHeight: value })
          }
          min={10}
          max={1000}
          labelValues={[]}
        />
      </HeadingCard>
    </FlexBox>
  );
}
