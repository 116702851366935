import { Card, CardList, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { defaultLayouts } from "Data/Layouts/defaultLayoutsData";
import { loadLayout } from "Functions/Layout/loadLayout";

export default function DefaultLayoutsMenu() {
  const handleClick = (layout) => {
    loadLayout(layout.layout);
  };

  return (
    <>
      {defaultLayouts.map((layout) => {
        return (
          <MenuItem key={layout.id} text={layout.title} onClick={() => (handleClick(layout))} />
        );
      })}
    </>
  );
}
