import HeadingCard from 'UtilityComponents/HeadingCard'
import React from 'react'
import AdetailerModel from './AdetailerModel'
import { Callout } from '@blueprintjs/core'

export default function Settings() {
  return (
    <HeadingCard title="ADetailer Settings">
        <AdetailerModel />
        <Callout>
        <p>Requires ADetailer extension to be installed and active, otherwise API call will fail.</p>
        </Callout>
    </HeadingCard>
  )
}
