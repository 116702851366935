import { MenuItem, TagInput } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import FlexBox from "UtilityComponents/FlexBox";
import Loading from "UtilityComponents/Loading";
import React from "react";
import ToggleInclusiveTags from "./ToggleInclusiveTags";
import { tags } from "Signals/tags";
import { snapshotFilter } from "Signals/appState";
import ToggleSavedOnly from "./ToggleSavedOnly";

export default function Filter() {
  const filterTags = tags.value.filter((tag) =>
    snapshotFilter.value.tagIds.includes(tag.id)
  );
  if (!tags || !filterTags) return <Loading />;

  const handleAdd = (tagId) => {
    const newTagIds = [...snapshotFilter.value.tagIds, tagId];
    snapshotFilter.value = { ...snapshotFilter.value, tagIds: newTagIds };
  };

  const handleDelete = (tagId, index) => {
    const newTagIds = snapshotFilter.value.tagIds.filter((id) => id !== tagId);
    snapshotFilter.value = { ...snapshotFilter.value, tagIds: newTagIds };
  };

  return (
    <FlexBox gap>
    <ToggleSavedOnly />
      <MultiSelect
        placeholder="Filter by tag"
        items={tags.value
          .filter(
            (tag) => !filterTags.some((filterTag) => filterTag.id === tag.id)
          )
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })}
        tagRenderer={(tag) => tag.name}
        tagInputProps={{
          onRemove: (value, index) => {
            const tag = filterTags[index];
            handleDelete(tag.id, index);
          },
        }}
        onItemSelect={(tag) => {
          handleAdd(tag.id);
        }}
        selectedItems={filterTags.sort((a, b) => a.name.localeCompare(b.name))}
        itemPredicate={(query, item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              text={item.name}
              onClick={handleClick}
              active={modifiers.active}
            />
          );
        }}
        popoverContentProps={{ className: "scrollable-dropdown" }}
        noResults={<MenuItem disabled={true} text="No results" />}
      />
      <ToggleInclusiveTags />
    </FlexBox>
  );
}
