import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loading from "UtilityComponents/Loading";
import FlexBox from "UtilityComponents/FlexBox";
import { tokens as stateTokens } from "Signals/tokens";
import { updateToken } from "Functions/Token/updateToken";
import HeadingCard from "UtilityComponents/HeadingCard";
import ListTokensItem from "./ListTokensItem";

export default function ReorderTokens({ cardId }) {
  const tokens = stateTokens.value
    .filter((t) => t.cardId === cardId)
    .sort((a, b) => a.order - b.order);
  if (!tokens) return <Loading />;

  const handleDragEnd = (result) => {
    if(!result.destination) return;
    
    const [reorderedItem] = tokens.splice(result.source.index, 1);
    tokens.splice(result.destination.index, 0, reorderedItem);
    tokens.forEach((item, index) => {
      updateToken({
        ...item,
        order: index,
      });
    });
  };

  return (
      
      <FlexBox column gap>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tokens">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <FlexBox gap column>
              {tokens.map((token, index) => (
                <Draggable
                  key={token.id}
                  draggableId={token.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ListTokensItem key={token.id} token={token} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              </FlexBox>
            </div>
          )}
          </Droppable>
        </DragDropContext>
      </FlexBox>
  );
}
