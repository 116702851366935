import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React, { useEffect } from "react";

export default function ThresholdB() {
  const [value, setValue] = React.useState(apiSettings.value.cnThresholdB);
  useEffect(() => {
    setValue(apiSettings.value.cnThresholdB);
  }, [apiSettings.value.cnThresholdB]);
  return (
    <FlexBox column>
      <p>Threshold B</p>
      <Slider
        min={0}
        max={100}
        stepSize={1}
        labelValues={[]}
        onRelease={(value) =>
          (apiSettings.value = {
            ...apiSettings.value,
            cnThresholdB: value,
          })
        }
        onChange={(value) => setValue(value)}
        value={value}
      />
    </FlexBox>
  );
}

