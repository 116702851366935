import {
  Button,
  Callout,
  Card,
  Checkbox,
  Icon,
  InputGroup,
  Tooltip,
} from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";
import { apiSettings } from "Signals/apiSettings";
import { apiConnected } from "Signals/appState";
import HeadingCard from "UtilityComponents/HeadingCard";
import { getApiStatus } from "API/getApiStatus";
import ReorderCategoriesButton from "Tiles/ListCategories/Actions/ReorderCategoriesButton";
import AddCategory from "Tiles/ListCategories/Actions/AddCategory";
import RefreshData from "Tiles/GenerationSettings/RefreshData";

export default function APISettings() {
  const handleClick = () => {
    getApiStatus();
  };

  return (
    <FlexBox column padding gap>
      <HeadingCard title="API Status">
        <FlexBox column bigGap>
          <FlexBox>
            <Icon
              size={50}
              icon={apiConnected.value ? "tick-circle" : "offline"}
              intent={apiConnected.value ? "success" : "danger"}
            />
          </FlexBox>
          <FlexBox>
            <PTEXT>{apiConnected.value ? "Connected" : "Disconnected"}</PTEXT>
          </FlexBox>
          <FlexBox>
            <Button onClick={handleClick} icon="refresh">
              Check API status
            </Button>
          </FlexBox>
          <FlexBox>
            <Tooltip content="Update A1111 API status every second. Necessary for progress bar to work. Disable if you will not be using an API for image generation.">
              <Checkbox
                checked={apiSettings.value.pollApi}
                onChange={(e) => {
                  apiSettings.value = {
                    ...apiSettings.value,
                    pollApi: e.target.checked,
                  };
                }}
              >
                Automatically check API status
              </Checkbox>
            </Tooltip>
          </FlexBox>
        </FlexBox>
      </HeadingCard>
      <RefreshData />
      <HeadingCard title="API URL">
        <InputGroup
          value={apiSettings.value.apiUrl}
          onChange={(e) => {
            apiSettings.value = {
              ...apiSettings.value,
              apiUrl: e.target.value,
            };
          }}
          placeholder="API URL"
        />
      </HeadingCard>
      <Callout title="Help">
        <div className="bp5-running-text">
          <p>
            Image generation requires an active connection to an Automatic1111
            API.
          </p>
          <p>
            You can install Automatic1111 from{" "}
            <a href="https://github.com/AUTOMATIC1111/stable-diffusion-webui">
              https://github.com/AUTOMATIC1111/stable-diffusion-webui
            </a>
          </p>
          <p>
            For the API to work you must edit the contents of your{" "}
            <code>webui-user.bat</code> file.
          </p>
          <p>
            This file is located in the root folder of your Automatic1111
            installation.
          </p>
          <p>
            Open the file and find the line which begins:
            <code>set COMMANDLINE_ARGS=</code>
          </p>
          <p>
            Add these two arguments to the end of the line:
            <code>
              --api --cors-allow-origins=https://app.deckai.io
            </code>
          </p>
          <p>Example:</p>
          <p>
            <code>
              set COMMANDLINE_ARGS= --api
              --cors-allow-origins=https://app.deckai.io
            </code>
          </p>
          <p>
            Note that data is only passed between your{" "}
            <strong>local browser</strong> and the API. Data is never sent to
            the servers which host deckai.io.
          </p>
        </div>
      </Callout>
    </FlexBox>
  );
}
