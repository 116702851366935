import { set } from "idb-keyval";
export const deleteAllDataFromDb = async () => {
  set("cards", []);
  set("tokens", []);
  set("tags", []);
  set("slots", []);
  set("categories", []);
  set("snapshots", []);
  set("layouts", []);
  set("generationPresets", []);
  set("apiSettings", []);
  set("settings", []);
};
