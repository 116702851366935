import { Button, Card, Checkbox, Icon } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { deleteSlot } from "Functions/Slot/deleteSlot";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlotId } from "Signals/slots";
import DeleteSlot from "Tiles/SingleSlot/Actions/DeleteSlot";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function ListSlotsItem({ slot }) {
  const selectSlot = () => {
    currentSlotId.value = slot.id;
  };

  const openSettings = () => {
    currentSlotId.value = slot.id;
    addTile("SingleSlot");
  };

  const handleDelete = () => {
    deleteSlot(slot.id)
  };

  return (
    <Card compact className={!slot.enabled && "disabled"} onClick={selectSlot}>
      <FlexBox spaceBetween gap>
        <FlexBox gap>
          <Icon icon="drag-handle-vertical" />
          <Checkbox
            checked={slot.enabled}
            onChange={() => {
              updateSlot({
                ...slot,
                enabled: !slot.enabled,
              });
            }}
          />
          <PTEXT>{slot.name}</PTEXT>
        </FlexBox>
        <FlexBox gap>
            <Button icon="cog" small onClick={openSettings} />
            <Button icon="trash" small onClick={handleDelete} intent="danger" />
        </FlexBox>
      </FlexBox>
    </Card>
  );
}
