import {
  Button,
  Card,
  Checkbox,
  FileInput,
  H5,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { importData } from "Functions/Data/importData";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function ImportData() {
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("Select file...");
  const [merge, setMerge] = React.useState(false);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    // Set file name
    setFileName(event.target.files[0].name);
  };

  const handleClick = () => {
    console.log("Importing...");
    if (file) {
      importData(file, merge);
    }
  };

  return (
    <HeadingCard title="Import Data">
      <FlexBox column gap>
        <FileInput text={fileName} onInputChange={handleChange} />
        <Tooltip content="Add to current data. If two entities have the same ID, the existing entity will be preserved and the imported entity ignored.">
          <Checkbox
            checked={merge}
            onChange={(e) => setMerge(e.target.checked)}
          >
            Merge with existing deck
          </Checkbox>
        </Tooltip>
        <FlexBox>
          <Button onClick={handleClick} intent="primary" icon="import">
            Import Data
          </Button>
        </FlexBox>
      </FlexBox>
    </HeadingCard>
  );
}
