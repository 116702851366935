import { snapshots } from "Signals/snapshots";

const getImageSeedFromSnapshotId = async (snapshotId) => {
  const snapshot = snapshots.value.find(
    (snapshot) => snapshot.id === snapshotId
  );
  if (!snapshot || !snapshot.info) return "";
  const info = JSON.parse(snapshot.info);
  return info.seed;
};

export default getImageSeedFromSnapshotId;
