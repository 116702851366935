import { Checkbox, SwitchCard, Tooltip } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnAvailable } from "Signals/appState";
import React from "react";

export default function ToggleEnabled() {
  return (
    <Tooltip content={!cnAvailable.value ? "ControlNet installation not detected" : ""}>
    <SwitchCard
      disabled = {!cnAvailable.value}
      checked={apiSettings.value.cnEnabled}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          cnEnabled: !apiSettings.value.cnEnabled
        };
      }}
    >ControlNet</SwitchCard>
    </Tooltip>
  );
}
