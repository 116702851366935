import { apiSettings } from "Signals/apiSettings";
import { cnModules } from "Signals/appState";
import axios from "axios";

export const getCnModules = async () => {
  const url = `${apiSettings.value.apiUrl}/controlnet/module_list`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.module_list.length === 0) {
      return;
    }
      cnModules.value =  data.module_list
  } catch (error) {
    cnModules.value = [];
    // state.apiStatus = "Error fetching data from API";
  }
};
