import { Checkbox } from "@blueprintjs/core";
import { snapshotFilter } from "Signals/appState";
import React from "react";

export default function ToggleSavedOnly() {
  return (
    <Checkbox
      className="m-0"
      checked={snapshotFilter.value.saved}
      onChange={() =>
        (snapshotFilter.value = {
          ...snapshotFilter.value,
          saved: !snapshotFilter.value.saved,
        })
      }
    >
      Saved Only
    </Checkbox>
  );
}
