import { apiSettings } from "Signals/apiSettings";
import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { generationPresets } from "Signals/generationPresets";
import { layouts } from "Signals/layouts";
import { settings } from "Signals/settings";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";
import { produce } from "immer";

export const importData = async (file, merge) => {
  const reader = new FileReader();

  reader.onload = async (event) => {
    const data = JSON.parse(event.target.result);
    if (!merge) {
      if (data.cards) cards.value = [];
      if (data.tokens) tokens.value = [];
      if (data.tags) tags.value = [];
      if (data.slots) slots.value = [];
      if (data.categories) categories.value = [];
      if (data.snapshots) snapshots.value = [];
      if (data.layouts) layouts.value = [];
      if (data.generationPresets) generationPresets.value = [];
    }

    if (data.tags) {
      tags.value = produce(tags.value, (draft) => {
        draft.push(
          ...data.tags.filter(
            (tag) => !draft.some((stateTag) => stateTag.id === tag.id)
          )
        );
      });
    }
    if (data.slots) {
      slots.value = produce(slots.value, (draft) => {
        draft.push(
          ...data.slots.filter(
            (slot) => !draft.some((stateSlot) => stateSlot.id === slot.id)
          )
        );
      });
    }
    if (data.categories) {
      categories.value = produce(categories.value, (draft) => {
        draft.push(
          ...data.categories.filter(
            (category) =>
              !draft.some((stateCategory) => stateCategory.id === category.id)
          )
        );
      });
    }
    if (data.tokens) {
      tokens.value = produce(tokens.value, (draft) => {
        draft.push(
          ...data.tokens.filter(
            (token) => !draft.some((stateToken) => stateToken.id === token.id)
          )
        );
      });
    }
    if (data.cards) {
      cards.value = produce(cards.value, (draft) => {
        draft.push(
          ...data.cards.filter(
            (card) => !draft.some((stateCard) => stateCard.id === card.id)
          )
        );
      });
    }
    if (data.snapshots) {
      snapshots.value = produce(snapshots.value, (draft) => {
        draft.push(
          ...data.snapshots.filter(
            (snapshot) =>
              !draft.some((stateSnapshot) => stateSnapshot.id === snapshot.id)
          )
        );
      });
    }
    if (data.layouts) {
      layouts.value = produce(layouts.value, (draft) => {
        draft.push(
          ...data.layouts.filter(
            (layout) =>
              !draft.some((stateLayout) => stateLayout.id === layout.id)
          )
        );
      });
    }
    if (data.generationPresets) {
      generationPresets.value = produce(generationPresets.value, (draft) => {
        draft.push(
          ...data.generationPresets.filter(
            (generationPreset) =>
              !draft.some(
                (stateGenerationPreset) =>
                  stateGenerationPreset.id === generationPreset.id
              )
          )
        );
      });
    }
    // Replace settings if we have some
    apiSettings.value = data.apiSettings ? data.apiSettings : apiSettings.value;
    settings.value = data.settings ? data.settings : settings.value;
  };

  reader.readAsText(file);
};
