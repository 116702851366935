import { Card } from "@blueprintjs/core";
import React from "react";
import ToggleAdetailer from "./ToggleAdetailer";
import ToggleAdetailerRestoreFaces from "./ToggleAdetailerRestoreFaces";
import Settings from "./Settings/Settings";
import FlexBox from "UtilityComponents/FlexBox";
import { apiSettings } from "Signals/apiSettings";

export default function Adetailer() {
  return (
    <FlexBox column gap>
      <ToggleAdetailer />
      {apiSettings.value.adetailerEnabled && <Settings />}
    </FlexBox>
  );
}
