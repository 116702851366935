import React from "react";
import ExportData from "./ExportData";
import ImportData from "./ImportData";
import FlexBox from "UtilityComponents/FlexBox";
import ClearData from "./ClearData";
import { Callout } from "@blueprintjs/core";
import LoadDefaultDeck from "./LoadDefaultDeck";

export default function ManageData() {
  return (
    <FlexBox column padding gap>
      <ExportData />
      <ImportData />
      <LoadDefaultDeck />
      <ClearData />
      <Callout title="Help">
        <div className="bp5-running-text">
          <p>
            All data is stored <strong>locally</strong> in your browser's{" "}
            <a href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API">
              internal database.
            </a>
          </p>
          <p>
            No data is ever sent to the servers which host deckai.io.
          </p>
          <p>Regularly exporting your data will help prevent accidental data loss.</p>
        </div>
      </Callout>
    </FlexBox>
  );
}
