import { Button, Checkbox, Popover, Tooltip } from "@blueprintjs/core";
import { clearAllData } from "Functions/Data/clearAllData";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function ClearData() {

  const handleClick = () => {
      clearAllData()
  };

  return (
    <HeadingCard title="Clear Data">
      <FlexBox>
        <Popover
          content={
            <FlexBox column gap padding>
                <p>Are you sure? This will remove all deck, card, snapshot, layout, and API data</p>
                <Button onClick={handleClick} intent="danger">YES</Button>
            </FlexBox>
          }
          position="bottom"
        >
          <Button intent="danger" icon="trash">
            DELETE ALL DATA
          </Button>
        </Popover>
      </FlexBox>
    </HeadingCard>
  );
}
