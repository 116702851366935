import React, { useEffect } from "react";
import ListCardsItem from "./ListCardsItem";
import FlexBox from "UtilityComponents/FlexBox";
import { cards } from "Signals/cards";
import { settings } from "Signals/settings";

export default function ListCards({ cardIds, disabled }) {
  // const style = {
  //   maxWidth: settings.value.cardWidth * 4,
  //   // width: settings.value.cardWidth * 4,
  //   // width: "100%",
  // };
  return (
      <FlexBox flexStart column alignEnd flexEnd minWidth>
        {cards.value
          .filter((card) => cardIds.includes(card.id))
          .sort((a, b) => cardIds.indexOf(a.id) - cardIds.indexOf(b.id))
          .map((card, index) => (
            <ListCardsItem
              key={card.id}
              card={card}
              disabled={disabled}
              index={index}
            />
          ))}
      </FlexBox>
  );
}
