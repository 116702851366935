import { apiSettings } from "Signals/apiSettings";
import { apiStatus, upscalers } from "Signals/appState";
import axios from "axios";

export const getUpscalers = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/upscalers`;

  try {
    const response = await axios.get(url);
    const data = response.data;

    upscalers.value = data.flatMap((upscaler) => upscaler.name);
  } catch {
    apiStatus.value = "Error";
  }
};
