import { Card, HTMLSelect, Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { checkpoints } from "Signals/appState";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function Checkpoint() {
  const handleChange = (event) => {
    apiSettings.value = {
      ...apiSettings.value,
      checkpoint: event.target.value,
    };
  };

  return (
    <HeadingCard title="Primary Checkpoint">
      <HTMLSelect
        options={
          checkpoints.value.length > 0
            ? checkpoints.value
            : ["No checkpoints detected"]
        }
        onChange={handleChange}
        value={apiSettings.value.checkpoint}
      ></HTMLSelect>
    </HeadingCard>
  );
}
