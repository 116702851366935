import DisplayCard from "UtilityComponents/DisplayCard";
import { Card, Text } from "@blueprintjs/core";
import RenderCardImage from "UtilityComponents/RenderCardImage";
import React from "react";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";
import PTEXT from "UtilityComponents/PTEXT";
import FlexBox from "UtilityComponents/FlexBox";
import { getImageSrc } from "Functions/Snapshot/getImageSrc";

export default function ListCardsItem({ card, disabled, index }) {
  const handleClick = (e) => {
    setCurrentCardId(card.id);
  };

  const style = {
    padding: "0.5rem",
  };
  const titleStyle = {
    // maxWidth:
    //   currentSlotId.value === slot.id || showCheckbox
    //     ? settings.value.cardWidth - 50
    //     : settings.value.cardWidth - 20,
    // maxWidth: settings.value.cardWidth - 50,
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // textTransform: "uppercase",
    maxWidth: "100%",
  };

  return (
    <div
      style={style}
      // interactive
      onClick={handleClick}
      // className={disabled && "disabled"}
    >
      <FlexBox flexEnd>
        <p 
// @ts-ignore
        style={titleStyle}>{card.name}</p>
      </FlexBox>
    </div>
  );
}
