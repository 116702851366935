import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import FlexBox from "../../../UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import PTEXT from "../../../UtilityComponents/PTEXT";
import { updateCard } from "Functions/Card/updateCard";
import RenderCardImage from "UtilityComponents/RenderCardImage";
import Resizer from "react-image-file-resizer";
import { max } from "lodash";

export default function UploadImage({ card }) {
  const maxWidth = 400;

  const [newImage, setNewImage] = React.useState(null);
  useEffect(() => {
    setImage(newImage);
  }, [newImage]);

  const setImage = (image) => {
    if (!image) {
      return;
    }
    updateCard({
      ...card,
      image: image,
    });
  };

  // https://www.npmjs.com/package/react-image-file-resizer
  const resizeImage = (image) => {
    try {
      Resizer.imageFileResizer(
        image,
        maxWidth,
        maxWidth,
        "WEBP",
        75,
        0,
        (uri) => {
          // setImage(uri);
          setNewImage(uri);
          // this.setState({ newImage: uri });
        },
        "base64"
        // 500,
        // 500
      );
    } catch (err) {
      console.log("error resizing image");
      console.log(err);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      resizeImage(file);
      // const reader = new FileReader();

      // reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result;
      //   // setImage(binaryStr);
      //   resizeImage(binaryStr);
      //   // cnImage.value = binaryStr;
      //   // console.log(binaryStr)
      // };
      // reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const style = {
    maxWidth: maxWidth,
    // height: card.image ? "400px" : "100px",
    // padding: 0,
    cursor: "pointer",
  };

  return (
    <FlexBox>
      <Card style={style}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {card.image && <RenderCardImage card={card} />}
          {!card.image && (
            <div style={{ height: "200px" }}>
              <FlexBox fullHeight padding>
                <PTEXT>Drag an image or click to upload</PTEXT>
              </FlexBox>
            </div>
          )}
        </div>
      </Card>
    </FlexBox>
  );
}
