import { Callout, Card, Icon } from "@blueprintjs/core";
import EmptyTile from "UtilityComponents/EmptyTile";
import FlexBox from "UtilityComponents/FlexBox";
import FlexCard from "UtilityComponents/FlexCard";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { categories as stateCategories } from "Signals/categories";
import { updateCategory } from "Functions/Category/updateCategory";

export default function DisplayOrder() {
  const categories = stateCategories.value
    .filter((c) => true)
    .sort((a, b) => a.displayOrder - b.displayOrder);
  if (!categories) return <EmptyTile>No Categories</EmptyTile>;

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const [reorderedItem] = categories.splice(result.source.index, 1);
    categories.splice(result.destination.index, 0, reorderedItem);
    categories.forEach((item, index) => {
      updateCategory({
        ...item,
        displayOrder: index,
      });
    });
  };
  return (
    <FlexBox column gap>
      <Callout>
        Change the order in which categories are displayed by the UI. This will
        not affect the generated image.
      </Callout>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={category.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Card compact
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <FlexBox padding gap flexStart interactive>
                        <Icon icon="drag-handle-vertical" />
                        {category.name}
                      </FlexBox>
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FlexBox>
  );
}
