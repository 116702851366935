import React from "react";
import { Card } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import ListSlots from "./ListSlots/ListSlots";
import ListCategories from "./ListCategories/ListCategories";
import LoadHand from "./Actions/LoadHand";
import PTEXT from "UtilityComponents/PTEXT";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function Hand({ hand }) {
  return (
    <>
      <HeadingCard title="Load Cards">
        <FlexBox column gap>
          <FlexBox>
            <LoadHand hand={hand} />
          </FlexBox>
          <ListSlots slots={hand.slots} />
        </FlexBox>
      </HeadingCard>
      <ListCategories hand={hand} />
    </>
  );
}
