import { MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { getAvailableTags } from "Functions/Tag/getAvailableTags";
import React from "react";
import ClearTags from "./ClearTags";
import FlexBox from "UtilityComponents/FlexBox";
import { tags as stateTags } from "Signals/tags";
import { currentSlot } from "Signals/slots";
import { updateSlot } from "Functions/Slot/updateSlot";
import { addTag } from "Functions/Tag/addTag";
import generateGuid from "UtilityFunctions/generateGUID";

export default function Tags() {
  const slot = currentSlot.value;
  if (!slot.filter) return null;
  const filterTags = stateTags.value.filter((tag) =>
    slot.filter.tagIds.includes(tag.id)
  );
  const tags = slot.filter.inclusiveTags ? getAvailableTags() : stateTags.value;

  if (!tags || !filterTags) return null;

  const handleAdd = (tagId) => {
    const newTagIds = [...slot.filter.tagIds, tagId];
    updateSlot({
      ...slot,
      filter: { ...slot.filter, tagIds: newTagIds },
    });
  };

  const handleDelete = (tagId) => {
    const newTagIds = slot.filter.tagIds.filter((id) => id !== tagId);
    updateSlot({
      ...slot,
      filter: { ...slot.filter, tagIds: newTagIds },
    });
  };

  const handleNewTag = (name) => {
    const id = addTag(name);
    handleAdd(id);
  };

  return (
    <FlexBox>
      <MultiSelect
        placeholder="Tags"
        items={tags
          .filter(
            (tag) => !filterTags.some((filterTag) => filterTag.id === tag.id)
          )
          .sort((a, b) => a.name.localeCompare(b.name))}
        tagRenderer={(tag) => tag.name}
        tagInputProps={{
          onRemove: (value, index) => {
            const tag = filterTags[index];
            handleDelete(tag.id);
          },
        }}
        onItemSelect={(tag) => {
          handleAdd(tag.id);
        }}
        selectedItems={filterTags}
        itemPredicate={(query, item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              text={item.name}
              onClick={handleClick}
              active={modifiers.active}
            />
          );
        }}
        createNewItemFromQuery={(name) => {
          return { id: generateGuid(), name };
        }}
        createNewItemRenderer={(query, active, handleClick) => {
          return (
            <MenuItem
              icon="add"
              text={`Add new tag: ${query}`}
              active={active}
              onClick={() => handleNewTag(query)}
              onSelect={() => handleNewTag(query)}
              shouldDismissPopover={false}
            />
          );
        }}
        popoverContentProps={{ className: "scrollable-dropdown" }}
        noResults={<MenuItem disabled={true} text="No results" />}
      ></MultiSelect>
      <ClearTags />
    </FlexBox>
  );
}
