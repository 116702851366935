import { Button, NonIdealState } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import React from "react";

export default function NoApiConnection() {
  return (
    <NonIdealState title="No API Connection" icon="offline">
      <Button
        onClick={() => addTile("APISettings")}
        icon="cog"
      >
        API Settings
      </Button>
    </NonIdealState>
  );
}
