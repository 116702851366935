import { Button, Popover } from "@blueprintjs/core";
import { loadDefaultDeck } from "Functions/Data/loadDefaultDeck";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function LoadDefaultDeck() {
  return (
    <HeadingCard compact title="Load Default Deck">
      <FlexBox>
        <Popover
          autoFocus
          content={
            <div className="p-1">
              <p>
                This will <strong>replace</strong> any current data. Are you
                sure?
              </p>
              <Button onClick={loadDefaultDeck} intent="danger">
                Yes
              </Button>
            </div>
          }
        >
          <Button intent="primary" icon="import">
            Load Default Deck
          </Button>
        </Popover>
      </FlexBox>
    </HeadingCard>
  );
}
