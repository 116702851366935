import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import DenoisingStrength from "./DenoisingStrength";
import { apiSettings } from "Signals/apiSettings";

export default function I2ISettings() {
  return (
    <>
      {apiSettings.value.img2imgEnabled && (
        <HeadingCard title="img2img Settings">
          <DenoisingStrength />
        </HeadingCard>
      )}
    </>
  );
}
