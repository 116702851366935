import { updateSlot } from "Functions/Slot/updateSlot";
import { cards, currentCardId } from "Signals/cards";
import { currentSlotId, slots } from "Signals/slots";
import DisplayCard from "UtilityComponents/DisplayCard";
import React from "react";

export default function ListCardsItem({ slotId, cardId }) {
  const card = cards.value.find((card) => card.id === cardId);
  const slot = slots.value.find((slot) => slot.id === slotId);
  if (!card) return null;
  const handleClick = (e) => {
    e.stopPropagation();
    currentCardId.value = card.id;
    currentSlotId.value = slot.id;
    updateSlot({
      ...slot,
      cardIds: [...slot.cardIds],
      wildcard: false,
      enabled: true,
    });
  };
  return (
    <DisplayCard
      card={card}
      disabled={slot?.lockValue}
      onClick={handleClick}
      onDoubleClick={undefined}
    />
  );
}
