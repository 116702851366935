import { categories } from "Signals/categories";
import { updateCategory } from "./updateCategory";

export const enableCategory = (categoryId) => {
  const category = categories.value.find(
    (category) => category.id === categoryId
  );
  if (!category) return;
  updateCategory({
    ...category,
    enabled: true,
  });
};
