import { Button, Overlay } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnImage } from "Signals/appState";
import { set } from "lodash";
import Painterro from "painterro";
import React, { useLayoutEffect } from "react";
import { useEffect, useState } from "react";

export default function Paint() {
  const [isOpen, setIsOpen] = useState(false);
  const [p, setP] = useState(null);

  const initializePainterro = () => {
    var p = Painterro({
      saveHandler: function (image, done) {
        cnImage.value = image.asDataURL();
        done(true);
        setIsOpen(false);
      },
      onClose: function () {
        setIsOpen(false);
      },
      defaultSize: `${Math.round(apiSettings.value.width)}x${Math.round(apiSettings.value.height)}`,
      defaultLineWidth: 50,
      defaultFillColor: '#000000',
      // defaultTool: "rect",
      id: "painterro",
    });
    p.show(cnImage.value ? cnImage.value : null);
    setP(p);
  };

  const handleClose = () => {
    setIsOpen(false);
    p.hide();
  };

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    width: "90%",
    marginLeft: "5%",
    marginTop: "5%",
  };

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>
        {cnImage.value ? "Edit" : "Draw"}
      </Button>
      <Overlay
        isOpen={isOpen}
        onClose={handleClose}
        onOpened={initializePainterro}
      >
        <div id="painterro" style={style}></div>
      </Overlay>
    </div>
  );
}
