import { Button, Popover } from "@blueprintjs/core";
import { addGenerationPreset } from "Functions/GenerationPreset/addGenerationPreset";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React, { useState } from "react";

export default function AddPreset() {
  const [name, setName] = useState("");

  const handleClick = () => {
    addGenerationPreset(name);
    setName("");
  };

  return (
    <HeadingCard title="Save Preset">
      <FlexBox>
        <Popover
          autoFocus
          content={
            <div className="p-1">
              <p>Preset name:</p>
              <input
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClick();
                  }
                }}
              />
            </div>
          }
        >
          <Button intent="primary" icon="add">
            Save Preset
          </Button>
        </Popover>
      </FlexBox>
    </HeadingCard>
  );
}
