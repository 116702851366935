import FlexBox from 'UtilityComponents/FlexBox'
import React from 'react'
import ListSlotsItem from './ListSlotsItem'

export default function ListSlots({slots}) {
  return (
    <FlexBox wrap>
        {slots.map((slot) => (
            <ListSlotsItem key={slot.id} slot={slot} />
        ))}
    </FlexBox>
  )
}
