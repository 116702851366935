import { getImageSrc } from "Functions/Snapshot/getImageSrc";
import React from "react";

export default function RenderCardImage({ card }) {
  if (!card.image) return;

  const src = getImageSrc(card.image);

  const style = {
    width: "100%",
    // height: "100%",
    // maxHeight: "100%",
    objectFit: "cover",
  };
  return (
    // @ts-ignore
    <img style={style} src={src} />
  );
}
