import { Button, Tooltip } from "@blueprintjs/core";
import { generateImage } from "Functions/API/generateImage";
import { apiConnected } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import NoApiConnection from "UtilityComponents/NoApiConnection";
import React from "react";

export default function GenerateImage() {
  if(!apiConnected.value) return (<NoApiConnection />)
  const handleGetImage = () => {
    generateImage({
      reuseSeed: false,
    });
  };

  const handleGetImageReuseSeed = () => {
    generateImage({
      reuseSeed: true,
    });
  };

  const handleGetImageSecondaryCheckpoint = () => {
    generateImage({
      useSecondaryCheckpoint: true,
    });
  };

  const handleGetImageSecondaryCheckpointReuseSeed = () => {
    generateImage({
      useSecondaryCheckpoint: true,
      reuseSeed: true,
    });
  };

  const handleGetImageWithRefiner = () => {
    generateImage({
      reuseSeed: false,
      refinerEnabled: true,
    });
  };

  const handleGetImageReuseSeedEnableRefiner = () => {
    generateImage({
      reuseSeed: true,
      refinerEnabled: true,
    });
  };

  const buttonStyle = {
    width: "100%",
    height: "100%",
  };
  return (
    <FlexBox column gap padding>
      <HeadingCard title="Primary Checkpoint" compact>
        <FlexBox column>
          <Button
            intent="primary"
            style={buttonStyle}
            onClick={handleGetImage}
            large
          >
            Generate
          </Button>
          <Tooltip
            content="Generate an image using the seed of the current snapshot"
            hoverOpenDelay={500}
          >
            <Button
              // intent="default"
              style={buttonStyle}
              onClick={handleGetImageReuseSeed}
            >
              Reuse Seed
            </Button>
          </Tooltip>
        </FlexBox>
      </HeadingCard>
      <HeadingCard title="Secondary Checkpoint" compact>
        <FlexBox column>
          <Button
            intent="primary"
            style={buttonStyle}
            onClick={handleGetImageSecondaryCheckpoint}
            large
          >
            Generate
          </Button>
          <Button
            // intent="primary"
            style={buttonStyle}
            onClick={handleGetImageSecondaryCheckpointReuseSeed}
          >
            Reuse Seed
          </Button>
        </FlexBox>
      </HeadingCard>
      <HeadingCard title="Refiner" compact>
        <FlexBox column>
          <Button
            intent="primary"
            style={buttonStyle}
            onClick={handleGetImageWithRefiner}
            large
          >
            Generate
          </Button>
          <Button
            // intent="primary"
            style={buttonStyle}
            onClick={handleGetImageReuseSeedEnableRefiner}
          >
            Reuse Seed
          </Button>
        </FlexBox>
      </HeadingCard>
    </FlexBox>
  );
}
