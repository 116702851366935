import { Card, Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useEffect } from "react";

export default function DenoisingStrength() {
  const [value, setValue] = React.useState(
    apiSettings.value.i2iDenoisingStrength
  );
  useEffect(() => {
    setValue(apiSettings.value.i2iDenoisingStrength);
  }, [apiSettings.value.i2iDenoisingStrength]);

  return (
    <FlexBox column>
      <p>Denoising Strength</p>
      <Slider
        min={0}
        max={1}
        stepSize={0.05}
        labelStepSize={1}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            i2iDenoisingStrength: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      />
    </FlexBox>
  );
}
