export const defaultLayouts = [
  {
    id: "build",

    title: "Build Prompt",
    layout: {
      first: {
        first: "ListCategories",
        second: {
          first: "ListCardsOptions",
          second: "ListCards",
          direction: "column",
          splitPercentage: 36,
        },
        direction: "row",
        splitPercentage: 67,
      },
      second: {
        first: "SingleCard",
        second: "Prompt",
        direction: "row",
        splitPercentage: 58,
      },
      direction: "row",
      splitPercentage: 77,
    },
  },
  {
    id: "buildCompact",
    title: "Build Prompt (compact)",
    layout: {
      first: "ListCategoriesCompact",
      second: {
        first: {
          first: "ListCardsOptions",
          second: "ListCards",
          direction: "row",
          splitPercentage: 17,
        },
        second: { first: "SingleCard", second: "Prompt", direction: "row" },
        direction: "row",
        splitPercentage: 75,
      },
      direction: "column",
      splitPercentage: 44,
    },
  },

  {
    id: "generate",
    title: "Generate Images",
    layout: {
      direction: "row",
      first: {
        first: "GenerateImage",
        second: "GenerationSettings",
        direction: "column",
        splitPercentage: 35,
      },
      second: {
        first: "SingleSnapshot",
        second: "ListSnapshots",
        direction: "row",
        splitPercentage: 38,
      },
      splitPercentage: 15,
    },
  },

  {
    id: "snapshots",
    title: "View Snapshots",
    layout: {
      first: "SingleSnapshot",
      second: "ListSnapshots",
      direction: "row",
      splitPercentage: 30,
    },
  },

  {
    id: "controlNet",
    title: "Img2Img and ControlNet",
    layout: {
      direction: "row",
      first: {
        first: "GenerateImage",
        second: "GenerationSettings",
        direction: "column",
        splitPercentage: 31.99480498835071,
      },
      second: {
        first: "ControlNet",
        second: {
          first: "SingleSnapshot",
          second: "ListSnapshots",
          direction: "row",
        },
        direction: "row",
        splitPercentage: 22.653885696663632,
      },
      splitPercentage: 15,
    },
  },

  {
    id: "settings",
    title: "Settings",
    layout: {
      direction: "row",
      splitPercentage: 66,
      first: {
        direction: "row",
        splitPercentage: 50,
        first: "ManageData",
        second: {
          direction: "column",
          splitPercentage: 50,
          first: "UISettings",
          second: "Layouts",
        },
      },
      second: "APISettings",
    },
  },
];
