import { computed, effect, signal } from "@preact/signals-react";

import { saveLayoutsToDb } from "Data/Layouts/saveLayoutsToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const layouts = signal([]);

// Save to DB on change
effect(() => {
  if(!layouts.value || layouts.value.length === 0) return;
    saveLayoutsToDb(layouts.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("layouts");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!layouts.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "layouts",
    data: layouts.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "layouts" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    layouts.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
