import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import DefaultLayoutsMenu from "./DefaultLayouts/DefaultLayoutsMenu";
import APIStatus from "./APIStatus/APIStatus";
import {
  Card,
  CardList,
  Classes,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import Build from "./Menus/Build";
import Generate from "./Menus/Generate";
import Settings from "./Menus/Settings";
import Snapshots from "./Menus/Snapshots";
import ListLayouts from "./SavedLayouts/ListLayouts";
import SaveLayout from "./SavedLayouts/SaveLayout";
import Help from "./Menus/Help";
import TitleText from "UtilityComponents/TitleText";

export default function NavMenu() {
  return (
    <FlexBox gap column flexStart alignStart>
      <FlexBox>
        <h3>deckai.io</h3>
      </FlexBox>
      <Menu className="m-0 p-1">
        <MenuDivider title="Windows" />
        <Build />
        {/* <MenuDivider/> */}
        <Generate />
        {/* <MenuDivider/> */}
        <Snapshots />
        {/* <MenuDivider/> */}
        <Settings />
        <Help />
        <MenuDivider title="Workflows" />
        <DefaultLayoutsMenu />
        <MenuDivider title="Saved Layouts" />
        <ListLayouts />
        <SaveLayout />
      </Menu>
      <APIStatus />
    </FlexBox>
  );
}
