import { enableCategory } from "Functions/Category/enableCategory";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlot } from "Signals/slots";

export default async function addCardToCurrentSlot(cardId) {
  const slot = currentSlot.value;

  if (!slot.multiSelect) {
    // Add card if not already in slot, else remove
    if (!slot.cardIds.includes(cardId)) {
      updateSlot({
        ...slot,
        cardIds: [cardId],
        enabled: true,
      });
      // Also enable category
      enableCategory(slot.categoryId);
    } else {
      updateSlot({
        ...slot,
        cardIds: [],
        enabled: false,
      });
    }
  }

  if (slot.multiSelect) {
    // Add card if not already in slot, else remove
    if (!slot.cardIds.includes(cardId)) {
      updateSlot({
        ...slot,
        cardIds: [...slot.cardIds, cardId],
        enabled: true,
      });
      // Also enable category
      enableCategory(slot.categoryId);
    } else {
      updateSlot({
        ...slot,
        cardIds: slot.cardIds.filter((id) => id !== cardId),
        // Disable if no cards left
        enabled: slot.cardIds.length > 1,
      });
    }

    // Disable slot if no cards in it
    // TODO: Can't do this because the above update isn't finished yet, annoying - is this what batch is for?
    // if (slot.cardIds.length === 0) {
    //   updateSlot({
    //     ...slot,
    //     enabled: false,
    //   });
    // } else {
    //   updateSlot({
    //     ...slot,
    //     enabled: true,
    //   });
    // }
  }
}
