import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { slots } from "Signals/slots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";

export const loadDefaultDeck = async () => {
  fetch(process.env.PUBLIC_URL + "/defaultDeck.json")
    .then((response) => response.json())
    .then((deck) => {
      categories.value = deck.categories;
      slots.value = deck.slots;
      cards.value = deck.cards;
      tokens.value = deck.tokens;
      tags.value = deck.tags;
    });
};
