import { MenuItem } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { isInLayout } from "Functions/Mosaic/isInLayout";
import React from "react";

export default function Help() {
  return (
    <MenuItem text="Help">
      <MenuItem
        text="Getting Started"
        onClick={() => addTile("GettingStarted")}
        disabled={isInLayout("GettingStarted")}
      />
    </MenuItem>
  );
}
