import { Button, Card, Checkbox, Divider, Tooltip } from "@blueprintjs/core";
import React from "react";
import ListSlots from "./ListSlots/ListSlots";
import AddSlot from "Tiles/SingleCategory/AddSlot";
import FlexBox from "UtilityComponents/FlexBox";
import { currentCategoryId } from "Signals/categories";
import { updateCategory } from "Functions/Category/updateCategory";
import TitleText from "UtilityComponents/TitleText";
import PTEXT from "UtilityComponents/PTEXT";
import { settings } from "Signals/settings";
import BigText from "UtilityComponents/BigText";
import EmptyCard from "UtilityComponents/EmptyCard";
import { addTile } from "Functions/Mosaic/addTile";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListCategoriesItem({ category }) {
  const handleClick = () => {
    currentCategoryId.value = category.id;
  };

  const handleOpenSettings = () => {
    currentCategoryId.value = category.id;
    addTile("SingleCategory");
  };

  const handleDoubleClick = () => {
    toggleEnabled();
  };

  const handleCheckBoxChange = (e) => {
    toggleEnabled();
  };
  const toggleEnabled = () => {
    updateCategory({
      ...category,
      enabled: !category.enabled,
    });
  };

  const style = {
    width: "100%",
    overflow: "auto",
    // overFlowX: "auto",
    padding: "0.5rem",
  };

  return (
    <Card
      className={!category.enabled && "disabled"}
      style={style}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}
    >
      <FlexBox
        padding
        flexStart
        gap
        alignStart
        column
        style={style}
        handleDoubleClick={handleDoubleClick}
      >
        <FlexBox spaceBetween gap>
          <FlexBox gap className="mb-1">
            <Checkbox
              checked={category.enabled}
              onChange={handleCheckBoxChange}
            />
            <PTEXT uppercase>{category.name}</PTEXT>
          </FlexBox>
          {currentCategoryId.value === category.id && (
            <Button icon="cog" small onClick={handleOpenSettings} />
          )}
        </FlexBox>
        {/* <Divider className="m-0 p-0" /> */}
        <ListSlots category={category} />
      </FlexBox>
    </Card>
  );
}
