import { apiSettings } from "Signals/apiSettings";
import { samplers } from "Signals/appState";
import axios from "axios";

export const getSamplers = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/samplers`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    samplers.value = data.flatMap((sampler) => sampler.name);
    // return data;
  } catch (error) {
    samplers.value = [];
    // state.apiStatus = "Error fetching data from API";
  }
};
