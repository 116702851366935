import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnModels } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function Model() {
  const handleChange = (value) => {
    apiSettings.value = {
      ...apiSettings.value,
      cnModel: value,
    };
  };
  return (
    <FlexBox column>
      <p>Model</p>
      <HTMLSelect
        options={cnModels.value.length > 0 ? cnModels.value : ["No models detected"]}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        value={apiSettings.value.cnModel}
      />
    </FlexBox>
  );
}
