import { categories, currentCategoryId } from "Signals/categories";
import generateGuid from "UtilityFunctions/generateGUID";
import { last } from "lodash";

export default function addCategory(name) {

  // Figure out last display order
  const lastCategory = categories.value.slice().sort((a, b) => b.displayOrder - a.displayOrder)[0];

  const id = generateGuid();
  categories.value = [
    ...categories.value,
    {
      id: id,
      name: name || "New Category",
      enabled: true,
      color: "#000000",
      displayOrder: lastCategory ? lastCategory.displayOrder + 1 : 0,
      renderOrder: lastCategory ? lastCategory.renderOrder + 1 : 0,
    },
  ];
  currentCategoryId.value = id;
}
