import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import MaskBlur from "./MaskBlur";
import InpaintFullRes from "./InpaintFullRes";
import InpaintFullResPadding from "./InpaintFullResPadding";
import InvertMask from "./InvertMask";
import ToggleCNMask from "./ToggleCNMask";

export default function Settings() {
  return (
    <FlexBox column gap>
      <MaskBlur />
      <InpaintFullRes />
      <InpaintFullResPadding />
      <FlexBox gap>
        <InvertMask />
        <ToggleCNMask />
      </FlexBox>
    </FlexBox>
  );
}
