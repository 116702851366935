import { enableCategory } from "Functions/Category/enableCategory";
import { updateSlot } from "Functions/Slot/updateSlot";
import { slots } from "Signals/slots";

export const loadHand = (hand) => {
  const lockedSlotIds = slots.value
    .filter((slot) => slot.lock)
    .map((slot) => slot.id);

  // Disable all slots except locked slots
  slots.value.forEach((slot) => {
    if (!lockedSlotIds.includes(slot.id)) {
      updateSlot({
        ...slot,
        enabled: false,
      });
    }
  });

  // Update value of slots to hand slot value except lockValueSlots
  // Also set wildcard to false for these slots
  hand.slots.forEach((slot) => {
    if (!lockedSlotIds.includes(slot.id)) {
      // Find slot to update
      const stateSlot = slots.value.find((s) => s.id === slot.id);
      updateSlot({
        ...stateSlot,
        enabled: true,
        cardIds: [...slot.cardIds],
        wildcard: false,
      });
      // Also enable category
      enableCategory(slot.categoryId);
    }
  });
};
