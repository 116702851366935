import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useEffect } from "react";

export default function CFGScale() {
  const [value, setValue] = React.useState(apiSettings.value.cfg_scale);
  useEffect(() => {
    setValue(apiSettings.value.cfg_scale);
  }, [apiSettings.value.cfg_scale]);
  return (
    <FlexBox column>
      <p>CFG Scale</p>
      <Slider
        min={1}
        max={20}
        labelValues={[]}
        stepSize={0.5}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            cfg_scale: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      />
    </FlexBox>
  );
}
