import { Card } from "@blueprintjs/core";
import { loadHand } from "Functions/Snapshot/loadHand";
import { currentSnapshotId } from "Signals/snapshots";
import React from "react";

export default function ListSnapshotsItem({ snapshot }) {
  const handleClick = (snapshotId) => {
    currentSnapshotId.value = snapshotId;
  };
  const handleDoubleClick = (snapshot) => {
    loadHand(snapshot.hand);
  };
  const imageStyle = {
    width: "100%",
    // maxHeight: "100%",
    cursor: "pointer",
  };
  return (
    <div>
      {!snapshot.image && (
        <Card
          interactive
          onClick={() => handleClick(snapshot.id)}
          onDoubleClick={() => handleDoubleClick(snapshot)}
        >
          <p>
            {new Date(snapshot.created).toLocaleString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            })}
          </p>
          <p>No image</p>
        </Card>
      )}
      {snapshot.image && (
        <img
          onClick={() => handleClick(snapshot.id)}
          onDoubleClick={() => handleDoubleClick(snapshot)}
          style={imageStyle}
          // src={`data:image/jpg;base64,${snapshot.image?.images[0]}`}
          // TODO: Legacy - won't need it with newly saved images
          src={
            snapshot.image.images
              ? `data:image/jpg;base64,${snapshot.image?.images[0]}`
              : snapshot.image
          }
        />
      )}
    </div>
  );
}
