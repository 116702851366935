import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loading from "UtilityComponents/Loading";
import FlexBox from "UtilityComponents/FlexBox";
import { tokens as stateTokens } from "Signals/tokens";
import { updateToken } from "Functions/Token/updateToken";
import HeadingCard from "UtilityComponents/HeadingCard";
import ListTokensItem from "./ListTokensItem";
import { Button, Tooltip } from "@blueprintjs/core";
import ReorderTokens from "./ReorderTokens";

export default function ListTokens({ cardId }) {
  const [isReordering, setIsReordering] = React.useState(false);

  const tokens = stateTokens.value
    .filter((t) => t.cardId === cardId)
    .sort((a, b) => a.order - b.order);
  if (!tokens) return <Loading />;

  return (
    <HeadingCard title="Tokens">
      <FlexBox column gap>
        {!isReordering && (
          <FlexBox gap wrap>
            {tokens.map((token) => (
              <ListTokensItem key={token.id} token={token} />
            ))}
          </FlexBox>
        )}
        {isReordering && <ReorderTokens cardId={cardId} />}
        <FlexBox flexEnd>
          <Tooltip content="Reorder Tokens">
            <Button
              small
              icon={isReordering ? "tick" : "sort"}
              intent={isReordering ? "success" : "none"}
              onClick={() => setIsReordering(!isReordering)}
            />
          </Tooltip>
        </FlexBox>
      </FlexBox>
    </HeadingCard>
  );
}
