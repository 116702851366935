import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { upscalers } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function Upscaler() {
  return (
    <FlexBox column>
      <p>Upscaler</p>
      <HTMLSelect
        options={
          upscalers.value.length > 0
            ? upscalers.value
            : ["No upscalers detected"]
        }
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            upscaler: event.target.value,
          };
        }}
        value={apiSettings.value.upscaler}
      />
    </FlexBox>
  );
}
