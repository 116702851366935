import { Button, Card, HTMLSelect } from "@blueprintjs/core";
import { deleteGenerationPreset } from "Functions/GenerationPreset/deleteGenerationPreset";
import { loadGenerationPreset } from "Functions/GenerationPreset/loadGenerationPreset";
import { generationPresets } from "Signals/generationPresets";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function ListPresets() {
  const handleClick = (presetId) => {
    loadGenerationPreset(presetId);
  };

  const handleDelete = (presetId) => {
    deleteGenerationPreset(presetId);
  };

  return (
    <HeadingCard compact title="Presets">
      <FlexBox column>
        {generationPresets.value.map((preset) => {
          return (
            <Card
              style={{padding:'0.5rem'}}
              key={preset.id}
              interactive
              onClick={() => {
                handleClick(preset.id);
              }}
            >
              <FlexBox spaceBetween>
                <PTEXT>{preset.name}</PTEXT>
                <Button small intent="danger" icon="trash" onClick={() => handleDelete(preset.id)} />
              </FlexBox>
            </Card>
          );
        })}
      </FlexBox>
    </HeadingCard>
  );
}
