import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import FlexCard from "UtilityComponents/FlexCard";
import React, { useEffect } from "react";

export default function UpdateWidth() {
  const [value, setValue] = React.useState(apiSettings.value.width);
  useEffect(() => {
    setValue(apiSettings.value.width);
  }, [apiSettings.value.width]);
  return (
    <FlexBox column>
      <p>Width</p>
      <Slider
        value={value}
        onRelease={(value) => 
          apiSettings.value = {
            ...apiSettings.value,
            width: value,
          }
        }
        onChange={(value) => {
          setValue(value);
        }}
        labelValues={[]}
        min={0}
        max={2000}
        stepSize={64}
      />
    </FlexBox>
  );
}
