import { Card, Slider } from "@blueprintjs/core";
import React from "react";
import ToggleRestoreFaces from "./Settings/ToggleRestoreFaces";
import FlexBox from "UtilityComponents/FlexBox";
import Refiner from "./Settings/Refiner/Refiner";
import Txt2Img from "./Settings/Txt2Img/Txt2Img";
import HRFix from "./Settings/HRFix/HRFix";
import SecondaryCheckpoint from "./Settings/Checkpoints/SecondaryCheckpoint";
import Adetailer from "./Adetailer/Adetailer";
import Checkpoint from "./Settings/Checkpoints/Checkpoint";
import DenoisingStrength from "./Settings/HRFix/DenoisingStrength";
import RefreshData from "./RefreshData";
import NoApiConnection from "UtilityComponents/NoApiConnection";
import { apiConnected } from "Signals/appState";

export default function GenerationSettings() {
  if (!apiConnected.value) return <NoApiConnection />;
  return (
    <FlexBox column padding gap>
      <RefreshData />
      <Checkpoint />
      <SecondaryCheckpoint />
      <Refiner />
      <Txt2Img />
      <ToggleRestoreFaces />
      <Adetailer />
      <HRFix />
    </FlexBox>
  );
}
