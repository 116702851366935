import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import Model from "./Model";
import { Card } from "@blueprintjs/core";
import Module from "./Module";
import Weight from "./Weight";
import ResizeMode from "./ResizeMode";
import ProcessorRes from "./ProcessorRes";
import GuidanceStart from "./GuidanceStart";
import GuidanceEnd from "./GuidanceEnd";
import ControlMode from "./ControlMode";
import PixelPerfect from "./PixelPerfect";
import ThresholdA from "./ThresholdA";
import ThresholdB from "./ThresholdB";
import HeadingCard from "UtilityComponents/HeadingCard";
import { apiSettings } from "Signals/apiSettings";

export default function Settings() {
  return (
    <>
      {apiSettings.value.cnEnabled && (
        <HeadingCard title="ControlNet settings">
          <FlexBox column gap>
            <Model />
            <Module />
            <Weight />
            <GuidanceStart />
            <GuidanceEnd />
            <ControlMode />
            <ResizeMode />
            <ProcessorRes />
            <PixelPerfect />
            <ThresholdA />
            <ThresholdB />
          </FlexBox>
        </HeadingCard>
      )}
    </>
  );
}
