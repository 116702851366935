import { Button } from "@blueprintjs/core";
import { loadHand } from "Functions/Snapshot/loadHand";
import React from "react";

export default function LoadHand({ hand }) {
  return (
    <Button icon="import" intent="primary" onClick={() => loadHand(hand)}>
      Load All Cards
    </Button>
  );
}
