import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import SaveSnapshot from "./SaveSnapshot";
import DeleteSnapshot from "./DeleteSnapshot";
import FlexBox from "UtilityComponents/FlexBox";

export default function Actions({ snapshot }) {
  return (
    <HeadingCard title="Snapshot Actions">
      <FlexBox gap>
        <SaveSnapshot snapshot={snapshot} />
        <DeleteSnapshot snapshot={snapshot} />
      </FlexBox>
    </HeadingCard>
  );
}
