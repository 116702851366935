import { MenuDivider, MenuItem } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { isInLayout } from "Functions/Mosaic/isInLayout";
import React from "react";

export default function Settings() {
  return (
    <MenuItem text="Settings">
      <MenuItem
        text="Layouts"
        onClick={() => addTile("Layouts")}
        disabled={isInLayout("Layouts")}
      />
      <MenuItem
        text="UI Options"
        onClick={() => addTile("UISettings")}
        disabled={isInLayout("UISettings")}
      />
      <MenuItem
        text="Manage Data"
        onClick={() => addTile("ManageData")}
        disabled={isInLayout("ManageData")}
      />
    </MenuItem>
  );
}
