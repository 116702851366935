import { Slider } from '@blueprintjs/core'
import { apiSettings } from 'Signals/apiSettings'
import FlexBox from 'UtilityComponents/FlexBox'
import PTEXT from 'UtilityComponents/PTEXT'
import React, { useEffect } from 'react'

export default function GuidanceStart() {
  const [value, setValue] = React.useState(apiSettings.value.cnGuidanceStart)
  useEffect(() => {
    setValue(apiSettings.value.cnGuidanceStart)
  }, [apiSettings.value.cnGuidanceStart])
  return (
    <FlexBox column>
        <p>Guidance Start</p>
        <Slider
            min={0}
            max={1}
            stepSize={0.1}
            labelValues={[]}
            onRelease={(value) => {
                apiSettings.value = {
                ...apiSettings.value,
                cnGuidanceStart: value,
                }
            }}
            onChange={(value) => {
                setValue(value);
            }}
            value={value}
            />
    </FlexBox>
  )
}
